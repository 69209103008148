<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-button type="warning" @click="adddialogVisible = true"
      >发送消息</el-button
    >
    <auto-table
      :DataList="list"
      :option="option"
      @changePage="changePage"
      :total="total"
    >
      <el-table-column label="操作">
        <!-- <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="
              $router.push({ name: 'appdownload', query: { id: row.id } })
            "
            >编辑</el-button
          >
        </template> -->
      </el-table-column>
    </auto-table>

    <!--  -->
    <el-dialog title="发送消息" :visible.sync="adddialogVisible" width="50%">
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="消息标题">
            <el-input v-model="title" placeholder="消息标题"></el-input>
          </el-form-item>
          <el-form-item label="消息内容">
            <el-input
              type="textarea"
              v-model="context"
              placeholder="消息内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="接收人">
            <el-radio v-model="view_type" label="用户">用户</el-radio>
            <el-radio v-model="view_type" label="教练">教练</el-radio>
            <el-radio v-model="view_type" label="机构">机构</el-radio>
          </el-form-item>

          <div class="part">
            <p class="part-title">发送用户</p>

            <div style="padding-left: 80px" v-if="view_type != '教练'">
              <div style="padding: 10px 0" class="flex al-c">
                <el-input
                  v-model="query"
                  placeholder="请输入用户电话"
                ></el-input>
                <el-button @click="getUser" type="primary">搜索</el-button>
              </div>

              <div>
                <el-transfer
                  v-model="checkUser"
                  :props="transprops"
                  :data="userList"
                  :titles="['用户列表', '选中用户']"
                >
                </el-transfer>
              </div>
            </div>
            <div style="padding-left: 80px" v-else>
              <coach-select :coachid.sync="coachid"></coach-select>
               <el-button @click="notChat" type="primary">禁言教练</el-button>
            </div>

           
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adddialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          type="primary"
          @click="add"
          >发送消息</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import coachSelect from "../../components/select/coachSelect.vue";
export default {
  components: { coachSelect },
  data() {
    return {
      list: [],
      nav: { firstNav: "消息管理", secondNav: "发送消息" },
      adddialogVisible: false,
      name: "",
      platform: "",
      currentPage: 1,
      total: 0,
      userList: [],
      checkUser: [],
      query: "",
      title: "",
      context: "",
      view_type: "用户",
      transprops: {
        key: "user_id",
        label: "nick_name",
      },
      coachid: "",
      option: [
        {
          name: "标题",
          value: "title",
        },
        {
          name: "创建时间",
          value: "create_time",
        },
      ],
    };
  },

  mounted() {
    this.getMsgList();
  },

  methods: {
    notChat() {
      this.$axios
        .post("user/coach/update", {
          id: this.coachid,
          can_chat: false,
        })
        .then((res) => {
          this.$message("成功");
        });
    },
    //搜索用户
    getUser() {
      if (this.query) {
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              login_name: this.query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    add() {
      let data = {
        view_type: this.view_type,
        send_id: JSON.parse(localStorage.getItem("managerInfo")).id,
        targetIds: this.checkUser,
        type: "推送",
        title: this.title,
        context: this.context,
        user_status:1
      };
      if (this.view_type == "教练") {
        data.targetIds = [this.coachid];
      }

      this.$axios({
        method: "post",
        url: "/user/pushHistory/batchPush",
        data,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message(res.data.message);
          this.adddialogVisible = false;
          this.getMsgList();
        } else {
          this.$message(res.data.message);
        }
      });
    },
    changePage(v) {
      this.currentPage = v;
      this.getMsgList();
    },
    getMsgList() {
      this.$axios({
        params: {
          send_id: JSON.parse(localStorage.getItem("managerInfo")).id,
          currenPage: this.currentPage,
          pageSize: 10,
          type: "推送",
        },
        method: "get",
        url: "user/pushHistory/queryManagerListPage",
      }).then((res) => {
        this.list = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
</style>